.backgorund__image {
  object-fit: cover;
  height: 85vh;
  filter: blur(1px);
  -webkit-filter: blur(1px);
  position: absolute;
}

.black__background {
  width: 100%;
  height: 100vh;
  z-index: 1;
  top: 0%;
  background: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: black;
}

.home_overlay_box {
  width: fit-content;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 15px;
  padding: 25px;
  max-width: 780px;
  z-index: 3;
}
@media screen and (max-width: 1024px) and (min-width: 500px) {
  .black__background {
    padding: 20px;
  }
}
@media screen and (max-width: 500px) {
  .home_overlay_box {
    width: 100%;
    padding: 25px;
    border-radius: 0;
  }
  .home_overlay_box .buttons_box.fixed_box {
    flex-direction: column !important;
  }
}
@media screen and (max-width: 1024px) and (min-width: 700px) {
  .home_overlay_box {
    border-radius: 15px;
    padding: 40px;
    width: fit-content;
  }
}
.home_overlay_box .top_title {
  color: #f1db39;
  font-weight: 400;
  margin-bottom: 12px;
  text-align: center;
}
.home_overlay_box .sub_title {
  color: white;
  font-weight: 400;
  text-align: center;
  font-size: 1.2em;
  word-break: break-word;
}
.home_overlay_box .buttons_box {
  margin-top: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home_overlay_box .buttons_box.fixed_box {
  z-index: 2;
  display: flex;
  flex-direction: row;
  padding: 0 5px;
}
.home_overlay_box .buttons_box.fixed_box .home_button {
  width: 100%;
  margin-bottom: 10px;
  max-width: 300px;
  font-weight: bold;
}
.buttons_box.fixed_box .home_button.right_btn {
  border-bottom: 1px solid #f1db39;
}
.home_overlay_box .buttons_box .home_button {
  font-weight: 400;
  text-align: center;
  font-size: 1.2em;
  background: #f1db39;
  color: white;
  border-radius: 50px;
  border: 0;
  width: 260px;
  height: 60px;
  color: black;
}
.black__background h3 {
  font-weight: 500;
  color: #fff;
  font-size: 50px;
}

.black__background .header___btn {
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 0.3);
  padding: 10px 15px;
}

.black__background .header___btn:hover {
  background-color: rgba(255, 255, 255, 1);
  color: #282c34;
}

/**Modal styles */

.consent__modal .modal-content {
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
}
.consent__modal .modal-header {
  border: none;
}
.consent__modal .modal-footer {
  border: none;
  display: flex;
  justify-content: flex-start;
}
.consent__modal .modal-body {
}
.consent__modal .modal-body input {
  height: 20px;

  vertical-align: top;
}
#flexCheckDefault:focus {
  border: 1px solid #f1db39;
  box-shadow: 0 0 0 0.25rem rgb(241 219 57/100%);
}
