.footer {
  background-color: black;
  width: 100%;
  padding-bottom: 16px;
}
.footer .main__content {
}
.footer .main__content ul {
  padding: 0;
  padding-top: 32px;
  padding-bottom: 24px;
  text-align: left;
}
.footer__link {
  text-decoration: underline !important;
}
.footer .main__content ul li {
  padding: 8px;
}
.footer .main__content ul li a {
  font-weight: normal;
  font-size: 1em;
  color: white;
  text-decoration: underline !important;
}

.footer .main__content .items {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  background-color: transparent;
}
.footer .main__content .items .item {
  /* width: 25%; */
  background-color: transparent;
  margin-bottom: 24px;
}
.footer .main__content .items .item img {
  max-height: 64px;
  background-color: transparent;
}

@media screen and (max-width: 768px) {
  .footer .main__content .items .item {
    width: 50%;
    height: 100%;
  }
}
@media screen and (max-width: 576px) {
  .footer .main__content .items .item {
    width: 100%;
    text-align: center;
    margin-bottom: 25px;
  }
  .footer .main__content ul {
    text-align: center;
  }
}
