.top__header {
  background-color: #f1db39;
  padding: 35px 0;
}

.top__header h4 {
  color: #090909;
  font-weight: 500;
  font-size: 2.1em;
}

.hero__top__box .content {
  max-width: 750px;
  padding: 40px 0;
}

.hero__top__box .content h4 {
  font-size: 2.3em;
  font-weight: bold;
  margin-bottom: 30px;
}

.hero__top__box .content p {
  font-weight: normal;
  font-size: 1em;
  margin-bottom: 30px;
}

.hero__top__box .content ul {
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 0;
}

@media only screen and (max-width: 768px) {
  .hero__top__box .content ul {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

.hero__top__box .content ul li {
  margin-right: 30px;
}

@media only screen and (max-width: 768px) {
  .hero__top__box .content ul li {
    margin-right: 0;
    width: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    margin-bottom: 40px;
  }
  .hero__top__box .content ul li img {
    width: 90%;
  }
}

.taxonomy__develop {
  background-color: #f1db39;
  padding: 50px 0;
  padding-top: 100px;
}

.taxonomy__develop .content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media only screen and (max-width: 992px) {
  .taxonomy__develop .content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.taxonomy__develop .content .left {
  max-width: 60%;
}

@media only screen and (max-width: 992px) {
  .taxonomy__develop .content .left {
    max-width: 100%;
    margin-bottom: 40px;
  }
}

.taxonomy__develop .content .left h4 {
  font-size: 2.3em;
  font-weight: bold;
  margin-bottom: 30px;
}

.taxonomy__develop .content .left p {
  font-weight: normal;
  font-size: 1em;
  margin-bottom: 30px;
}

.who__is__it__for {
  background: #ececec;
  padding: 40px 0;
}

.who__is__it__for .content h4 {
  font-size: 2.3em;
  font-weight: bold;
  margin-bottom: 40px;
}

.who__is__it__for .content .items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media only screen and (max-width: 992px) {
  .who__is__it__for .content .items {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.who__is__it__for .content .items .item {
  width: 28.3%;
  background: #fff;
  padding: 24px;
  border-radius: 5px;
}

@media only screen and (max-width: 992px) {
  .who__is__it__for .content .items .item {
    width: 100%;
  }
}

.who__is__it__for .content .items .item h5 {
  font-weight: bold;
  font-size: 1.35em;
  margin-bottom: 20px;
}

.who__is__it__for .content .items .item p {
  font-size: 1em;
}

.who__is__it__for .content .spacer {
  width: 7.5%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
}

@media only screen and (max-width: 992px) {
  .who__is__it__for .content .spacer img {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}

.contribute {
  padding: 50px 0;
}

.contribute .content {
  max-width: 67%;
}

@media only screen and (max-width: 992px) {
  .contribute .content {
    max-width: 100%;
  }
}

.contribute .content h4 {
  font-size: 2.3em;
  font-weight: bold;
  margin-bottom: 30px;
}

.contribute .content p {
  font-weight: normal;
  font-size: 1em;
  margin-bottom: 30px;
}

.contribute .content .feedback {
  display: inline-block;
  text-decoration: none;
  background: #000;
  color: #fff;
  padding: 10px 35px;
  border-radius: 25px;
}
/*# sourceMappingURL=style.css.map */
