.terms__page {
  margin: 24px 0;
  width: 100%;
  overflow: hidden;
}
.terms__page h2 {
  font-weight: bolder;
}
.terms__page h2 {
  font-weight: bold;
  margin-bottom: 16px;
  margin-top: 16px;
}
.terms__page .item {
  width: 100%;
  border-bottom: 1px solid rgb(233, 233, 233);
  padding: 0 16px;
}
.item:nth-child(odd) {
  background-color: rgb(245, 245, 245);
}
.item:last-child {
  border-bottom: 1px solid transparent;
}
.terms__page .item h5 {
  width: 100%;
  font-weight: 16;
  font-weight: 500;
  margin-bottom: 0;
  padding: 16px 0;
}
.terms__page .item p {
  width: 100%;
  font-style: 15px;
  font-weight: normal;
  padding: 16px 0;
}
.terms__page .ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.terms__page .li {
  padding-left: 16px;
}

.terms__page .li::before {
  content: '•'; /* Insert content that looks like bullets */
  padding-right: 8px;
  color: rgb(0, 0, 0); /* Or a color you prefer */
}
