.App-header {
  font-size: calc(0.7em + 2vmin);
  color: white;

  padding: 0 !important;
  margin: 0;
  min-height: 15vh;
  height: fit-content;
  z-index: 3;
  background: rgba(0, 0, 0, 0.9);
}
.App-header .header__container {
  justify-content: space-between;
}

.image__logo {
  z-index: 10;
  font-size: 3.2em;
  letter-spacing: 12px;
  color: #fff;
}
.image__logo span {
  letter-spacing: 12px;
}
.nav__item__container {
  z-index: 2;
  display: flex;
  max-width: 1920px;
  justify-content: right;
  align-items: center;
}
@media screen and (max-width: 1200px) {
  .nav__item__container {
    width: unset;
    max-width: unset;
  }
}

.nav__item__container li a {
  margin-left: 20px;
  font-size: 0.6em;
  font-weight: bold;
  color: #fff;
  font-weight: 700;
  vertical-align: middle;
}

.bm-burger-button {
  position: absolute;
  width: 36px;
  height: 30px;
  right: 36px;
  top: 38px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #cecece;
}

 
/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background-color: #f1db39 !important;
  opacity: 1 !important;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
 Sidebar wrapper styles
 Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
 */
.bm-menu-wrap {
  position: fixed;
  height: 100%;
  left: 0px;
  top: 0px;
}

/* General sidebar styles */
.bm-menu {
  background: #373a47;
  padding: 0.5em 0.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
  top: 0px;
  left: 0;
  display: block !important;
}

.menu-item {
  color: #fff;
  text-decoration: none;
  margin-top: 20px;
  font-size: 1.4em;
}
@media screen and (max-width: 1920px) {
  .nav__item__container {
    max-width: 1200px !important;
  }
}

@media only screen and (max-width: 350px) {
  .bm-burger-button {
    right: 15px;
  }
}
 

@media only screen and (min-width: 970px) and (max-width: 1200px) {
  .App-header .header__container {
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 15px;
     
  }
  .App-header .header__container ul {
    margin: unset;
  }
  .App-header .header__container ul li:first-child a{
   margin-left: 0;
  }
 }