.link__container {
  text-align: center;
  margin: 30px auto;
  background-color: rgba(0, 0, 0, 0.3);
  width: fit-content;
}

.rest__btn {
  display: flex;
  padding-bottom: 10px;
  background-color: #f1db39;
}

.rest__btn button {
  margin-left: 8.5% !important;
  max-height: 45px;
}
.mitch-d3-tree.boxed-tree.default .node .body-group{
}



.mitch-d3-tree.boxed-tree.default .node.selected .body-group .body-box {
  stroke: #000000;
}

.mitch-d3-tree.boxed-tree.default .node .body-group .body-box {
  stroke: #000000;
  rx: 0;
  ry: 0;
  height: 90px;
  width: 250px;
  x:0px;
  transform: translate(-20px, 0);


}

.rest__btn button svg {
  fill: white;
}

.treeContainer {
  max-width: 1080px;
  margin: 0 auto;
  height: 70vh;
  border-radius: 5px;
  box-sizing: border-box;
}

.title-group {
  display: none !important;
}

.node {
  /* stroke: black; */
  border-radius: 0px;
 
}

.node .body-group .body-box {
  fill: #000000 !important;
  border-radius: 0%;
}
.link {
  fill: none;
  stroke: #ccc;
  stroke-width: 1.5px;
}

.selected .body-group .body-box {
  fill: black !important;
}

 
.collapsed .body-group g text {
  font-size: 0.95em;
  opacity: 1;
  color: white !important;
  fill: white !important;
  background: white !important;
  font-weight: bold !important;
}

.childless .body-group .body-box {
  fill: #ffffff !important;
}
.selected .body-group .body-box,
.expanded .body-group .body-box {
  fill: #f1db39 !important;
}

.selected .body-group g text,
.expanded .body-group g text,
.childless .body-group g text,
.expanded .body-group g text {
  fill: rgb(0, 0, 0) !important;
}

.title-box,
.title-box .d3plus-textBox {
  display: none !important;
}

text {
  font-size: 1em !important;
  text-anchor: middle;
}

.def__container {
  background-color: #fff;
  padding: 15px;
  margin-top: 16%;
}

.rest__btn .breadcrumb__container ul {
  display: flex;
  flex-wrap: wrap;
}

.rest__btn .breadcrumb__container ul .item {
  margin: 5px;
  cursor: pointer;
  color: #000;
  padding: 0 !important;
  background-color: transparent;
}

.rest__btn .breadcrumb__container ul .bread__crumb__pointer {
  margin-top: 4px;
}

.rest__btn .breadcrumb__container ul .bread__crumb__pointer svg g g path {
  fill: black !important;
}

.def__container .def__item {
  margin: 20px 0;
  padding: 10px;
  border-radius: 5px;
  font-weight: 700;
  font-size: 1.35em;
  position: relative;
}

.def__container .def__item .three__dots {
  position: absolute;
  right: 5px;
  top: 5px;
  cursor: pointer;
  transform: rotate(90deg);
}

.feedback__modal .modal-dialog {
  width: fit-content;
  height: fit-content;
}

.feedback__modal .modal-body {
  padding: 0 !important;
}

.feedback__modal form {
  padding: 15px;
}

.feedback__modal .form__container {
  margin: 0 auto;
}

.accordion {
  transition: 0.5s all ease;
  height: 0px;
  overflow: hidden;
  padding: 5px;
}

.panningContainer {
  transform: translate(-17.111053466796875, -137.77777099609375) scale(1, 1) !important;
}

.accordion__area h3 {
  background-color: #7fbaec;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.collapsed__accordion {
  min-height: fit-content;
  height: 150px;
  overflow-y: auto;
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.8);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.8);
}

.tree__definition__container {
  left: unset !important;
  right: 0;
  width: 700px !important;
  z-index: 1000 !important;
}

.tree__definition__container .bm-menu {
  background-color: rgb(197, 197, 197);
  padding: 20px;
}

.close__menu svg {
  height: 15px !important;
}

.close__menu {
  color: black !important;
  cursor: pointer;
  font-weight: bold;
  width: fit-content;
}

.sidebar__item___container .def__item {
  background-color: #fff;
  padding: 10px 20px;
  color: #000;
  border: 1px solid #000;
}

.sidebar__item___container h3 {
  font-weight: bold !important;
}
.sidebar__item___container .def__item .header__items {
  justify-content: space-between;
}

.sidebar__item___container .def__item .accordion__container {
  display: flex;
}

.sidebar__item___container .def__item .accordion__area {
  width: fit-content;
}

.sidebar__item___container .def__item .accordion__area h3 {
  background-color: #fff;
}

.sidebar__item___container .def__item .accordion__area span {
  border: 1px solid black;
  padding: 0px 7px;
}

.sidebar__item___container .def__item .accordion__area .collapsed {
  border: 1px solid black;
  padding: 0px 11px;
}

.tree__page .bm-burger-button {
  display: none;
}

.sidebar__item___container .bm-item-list {
  height: unset !important;
}

.sidebar__item___container .divider__line {
    width: 8%;
    margin: 0 auto;
    opacity: 1;
}

.tree__page .bm-overlay {
  display: none !important;
}
