.full__taxonomy__header__area {
  border: 1px solid #505050;
}
.page__container .header__area {
  padding: 15px 0;
}
.page__container .page__header__area p {
  max-width: 50%;
}
.page__container .header__area h1,
.page__container .header__area h3 {
  font-weight: bolder;
  max-width: 50%;
}

.page__container .header__area .example__container {
  margin-top: 15px;
}

.page__container .header__area .example__container h3 {
  margin: 0;
}

.page__container .header__area .example__container ul li {
  padding: 5px !important;
  margin-left: 5px;
}

.page__container .header__area .example__container ul li img {
  height: 12px;
  margin-right: 12px;
  vertical-align: middle;
  vertical-align: -webkit-baseline-middle;
}

.page__container .header__area p {
  word-break: keep-all;
  text-align: left;
}

.page__container .header__area p .expan__definition {
  border-bottom: 1px solid black;
  font-size: 1.6em;
}
.bread__crumb__taxonomy {
  background-color: #f1db39;
  height: fit-content;
  min-height: 65px;
}

.page__container .bread__crumb__container {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  height: fit-content;
  min-height: 50px;
  padding: 0;
}

.page__container .bread__crumb__container li {
  padding: 5px !important;
}
.page__container .bread__crumb__container .page__reset_button span {
  border-bottom: 1px solid #000;
  cursor: pointer;
}
.page__container .bread__crumb__container .bread__crumb__item {
  cursor: pointer;
  word-break: keep-all;
}

.page__container .header__area .btn__area__container .first__btn {
  border-radius: 50px;
  padding: 15px 10px;
}
.page__container .header__area .btn__area__container .first__btn svg,
.page__container .header__area .btn__area__container .second__btn svg {
  height: 20px;
  margin-right: 5px;
}
.page__container .header__area .btn__area__container .first__btn span {
  border-bottom: 1px solid #000;
  font-weight: 700;
}

.page__container .header__area .btn__area__container .second__btn {
  border-radius: 50px;
  padding: 15px 10px;
}

.page__container .header__area .btn__area__container .second__btn span {
  border-bottom: 1px solid #000;

  font-weight: 700;
  font-weight: bold;
}

.page__container .card-container {
  background-color: #d7d7d7;
  padding: 25px 0;
}

.page__container .card-container .item__card {
  padding: 15px 15px 15px 15px;

  background-color: #fff;
  border-radius: 2px;
  height: 100%;
  margin-bottom: 20px;
  position: relative;
}
.page__container .card-container .special__card {
  height: fit-content;
  min-height: 200px !important;
}
.page__container .card-container .special__card h3 {
  cursor: default !important;
}
.page__container .card-container .item__card .header__area {
  border: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.page__container .card-container .item__card .header__area .card__header {
  justify-content: space-between;
}
.page__container .card-container .item__card .header__area button {
  height: fit-content;
  padding: 0 20px;
}
.page__container
  .card-container
  .item__card
  .header__area
  ul
  li
  .bullet__point {
  height: 10px;
  margin-right: 10px;
}
.page__container .card-container .item__card .header__area h3 {
  margin-bottom: 15px;
  cursor: pointer;
}
.page__container .card-container .item__card .header__area h3 svg {
  vertical-align: text-top;
}

.page__container
  .card-container
  .item__card
  .header__area
  .btn__area__container
  .second__btn {
  letter-spacing: normal;
  height: 45px;
}
.page__container
  .card-container
  .item__card
  .header__area
  .feedback__card__btn
  svg {
  height: 15px;
}
.feedback__btn {
  display: flex;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
  position: relative;
  height: 25px;
  color: #000 !important;
}
.feedback__btn hr {
  position: absolute;
  position: absolute;
  width: 80%;
  right: 0px;
  bottom: -15px;
  opacity: 1;
}
.feedback__btn svg {
  height: 15px;
  margin-top: 5px;
  margin-right: 3px;
}
@media only screen and (max-width: 800px) {
  .page__container .card-container .item__card .header__area p {
    max-width: 100%;
  }
  .page__container .header__area p,
  .page__container .header__area h1,
  .page__container .header__area h3 {
    max-width: 100%;
  }

  /* .page__container .card-container .item__card {
    min-height: 67vh;
  } */

  .page__container
    .card-container
    .item__card
    .header__area
    .btn__area__container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    left: 0px;
    bottom: 0;
    padding: 10px;
  }
  .page__container .header__area .btn__area__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  .page__container .header__area .btn__area__container button {
    margin-bottom: 15px;
    padding-left: 0;
    text-align: left;
  }

  .page__container
    .card-container
    .item__card
    .header__area
    .btn__area__container
    .first__btn {
    margin-bottom: 15px;
  }

  .page__container
    .card-container
    .item__card
    .header__area
    .btn__area__container
    .first__btn,
  .page__container
    .card-container
    .item__card
    .header__area
    .btn__area__container
    .second__btn {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1200px) {
  /* .page__container .card-container .item__card {
    min-height: 65vh;
  } */
  .page__container .card-container .item__card .header__area p {
    max-width: 100%;
  }
  .page__container
    .card-container
    .item__card
    .header__area
    .btn__area__container {
    display: flex;
  }
  .page__container .header__area .btn__area__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
  .page__container .header__area .btn__area__container button {
    margin-bottom: 15px;
    padding-left: 0;
    text-align: left;
  }
}
@media only screen and (min-width: 1200px) {
  /* .page__container .card-container .item__card {
    min-height: 44vh;
  } */
}
@media only screen and (max-width: 770px) {
  .full__taxonomy__header__area {
    padding-left: 10px;
  }
}
