.privacy__page {
  margin: 24px 0;
  width: 100%;
  overflow: hidden;
}

.privacy__page h1 {
  font-weight: bolder;
}
.privacy__page h2 {
  font-weight: bold;
  margin-bottom: 16px;
  margin-top: 16px;
}
.privacy__page .ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.privacy__page .li {
  padding-left: 16px;
}
.privacy__page .li::before {
  content: '•'; /* Insert content that looks like bullets */
  padding-right: 8px;
  color: rgb(0, 0, 0); /* Or a color you prefer */
}
